<template>
  <div class="contents" id="contents">
    <router-view></router-view>
  </div>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';

export default {
  name: 'Center',
  mixins: [mixinHelperUtils],
  data() {
    return {
    };
  },
};
</script>
